import React from 'react'
import RevisionsPage from '../components/RevisionsPage'
import Layout from '../components/Layout/Layout'

function Revisions(props) {
  return (
    <Layout {...props}>
      <RevisionsPage {...props} />
    </Layout>
  )
}
export default Revisions
