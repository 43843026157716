import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { MoreHoriz as MoreHorizIcon } from '@material-ui/icons'
import { TableRow as TableRevisions } from '../../Contents/TableRow'
// import { parseDate, parseSource } from '../../utils/tools'
import DetailRevision from './DetailRevision'

const columns = [
  {
    title: 'Fuente', field: 'source'
    // render: (rowData) => `${parseSource(rowData)}`,
  },
  {
    title: 'Status', field: 'status'
    // render: (rowData) => (rowData.success ? 'Enviado' : 'Fallido'),
  },
  { title: 'Mensaje id', field: 'message_result.MessageId' },
  {
    title: 'Fecha de creación',
    field: 'timestampCreatedISO'
    // render: (rowData) => {
    //   let date = new Date(rowData.timestampCreatedISO);
    //   date.setHours(date.getHours() - (new Date().getTimezoneOffset() / 60));
    //   return `${parseDate(date)}`
    // },
  },
]

export default function ListRevisions(props) {
  const classes = useStyles()
  const { rows, title } = props
  const [componentDetail, setComponentDetail] = useState(null)
  const actions = [
    {
      icon: () => <MoreHorizIcon fontSize="large" />,
      tooltip: 'Detalle revisión',
      onClick: (event, rowData) => handleOpenDetail(event, rowData),
    },
  ]

  const handleOpenDetail = (event, items) => {
    setComponentDetail(
      <DetailRevision
        setCallBack={setComponentDetail}
        items={items}
        {...props}
      />
    )
  }
  return (
    <div className={classes.root}>
      <TableRevisions
        title={title}
        actions={actions}
        columns={columns}
        data={rows ? rows : []}
      />
      {componentDetail}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))
