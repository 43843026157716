import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  DialogContent,
  DialogActions,
  DialogTitle as MuiDialogTitle,
  IconButton,
  Typography,
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import { s3PublicUrl } from '../../utils/Storage'


function DialogTitle(props) {
  const classes = useStyles()
  const { children, onClose, loading, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h5" className={classes.title}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          disabled={Boolean(false)}
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
}

export default function DetailRevision(props) {
  const { items, setCallBack } = props
  const [open, setOpen] = useState(true)
  const [newRow, setNewRow] = useState([])
  const [prepareData, setPrepareData] = useState(true)

  const handleClose = () => {
    setOpen(false)
    setCallBack(null)
  }

  const renderRows = async () => {
    let row = []
    let item = items.body
    console.log(items)
    if ( !item ) {
      item = {}
      item['tipo'] = 'Error'
      item['msg'] = items.msg
    }
    
    for( var key in item) {
      if (item[key] !== 'undefined' ) {
        if ( key === 'attachment') {
          for (let i = 0; i < item[key].length; i++) {
            let url = await s3PublicUrl(item[key][i])
            console.log(url)
            row.push(
              <TableRow key={`${key}-${i}`}>
                <TableCell component="th">
                  <strong>{`${key}-${i}`}</strong>
                </TableCell>
                <TableCell><a href={url}>Descargar</a></TableCell>
              </TableRow>
            )
          }
        } else {
          row.push(
            <TableRow key={key}>
              <TableCell component="th">
                <strong>{key}</strong>
              </TableCell>
              <TableCell>{JSON.stringify(item[key])}</TableCell>
            </TableRow>
          )
        }
      }
    }
    setNewRow(row)
  }
  useEffect(() => {
    setPrepareData(false)
    if ( prepareData ) renderRows()

  }, [prepareData])


  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
      >
        <DialogTitle
          id="form-dialog-title"
          onClose={handleClose}
          loading={true}
        >
          Detalle de la revision
        </DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table size="small">
              <TableBody>
                {newRow && newRow}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  title: {
    color: '#000000',
    fontFamily: 'sans-serif',
    fontSize: '20px',
    fontWeight: 'bold',
    lineHeight: '33px',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))
