import React, { useEffect } from 'react'
import { withWidth } from '@material-ui/core'
import { isLoggedIn } from '../../utils/auth'
import { navigate } from 'gatsby'
import ConsultRevisions from './ConsultRevisions'
import ListRevisions from './ListRevisions'
// import DetailRevisionNotAdmin from './DetailRevisionNotAdmin'

function RevisionsPage(props) {
  useEffect(() => {
    ;(async () => {
      if (await !isLoggedIn()) {
        navigate('/signin/')
      }
    })()
  }, [])

  // if (!getCurrentUser()['custom:isAdmin']) {
  //   return <DetailRevisionNotAdmin {...props} />
  // }

  return (
    <ConsultRevisions {...props}>
      <ListRevisions title="historial de correos usando la licencia" />
    </ConsultRevisions>
  )
}

export default withWidth()(RevisionsPage)
